<!--
* @description:
* @fileName single-input.vue
* @author hvv
* @date 2022/08/23 14:29:18
!-->
<template>
  <div class="single-inputBox">
    <div class="form-outlineBox">
      <div class="form-index">
        <img
          src="https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/MEHZ/images/needForm.png"
          alt=""
          v-if="res.required"
          class="requireImg"
        />
        {{ index + 1 >= 10 ? index + 1 : '0' + (index + 1) }}
      </div>
      <div class="form-content">
        <div class="form-label">
          <el-input
            v-model="res.label"
            placeholder="请输入问题"
            class="input_inp_name"
          />
          <el-input
            v-model="res.message"
            placeholder="请输入内容描述"
            class="input_inp_desc"
          />
        </div>
        <div class="form-question">
          <el-input
            readonly
            placeholder="待填写者填写"
            class="input_inp_userInsert"
          />
        </div>
      </div>
    </div>
    <div class="form-config">
      <div>
        <!-- 数据验证:
        <el-select
          v-model="res.rulesConfig"
          placeholder="请选择数据验证方式"
          size="small"
        >
          <el-option
            v-for="(item, index) in ruleOptions"
            :key="index"
            :label="item.label"
            :value="item.value"
          />
        </el-select> -->
      </div>

      <div class="config-right-plane">
        <el-checkbox v-model="res.required" label="必填" size="small" />
        <el-checkbox v-model="res.isSearch" label="隔离字段" size="small" />
        <el-icon class="icon" @click="deleteFormItem()">
          <DeleteFilled />
        </el-icon>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  export default defineComponent({
    name: 'single-input.vue',
    props: {
      res: {
        type: Object,
        default: {},
      },
      index: {
        type: Number,
        default: 0,
      },
    },

    emits: ['delete-form-item'],
    setup(props, { emit }) {
      const state = reactive({
        ruleOptions: [
          {
            label: '手机号',
            value: 'phoneReg',
          },
          {
            label: '邮箱',
            value: 'emailReg',
          },
        ],
      })

      const deleteFormItem = () => {
        emit('delete-form-item', props.index)
      }

      return {
        ...toRefs(state),
        deleteFormItem,
      }
    },
  })
</script>
<style lang="scss" scoped>
  .single-inputBox {
    width: 100%;
    .form-config {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px 40px;
      margin-top: 10px;
      border-top: 1px solid #e0e0e0;
      .config-right-plane {
        .icon {
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }
    .form-outlineBox {
      display: flex;
      width: 100%;
      padding: 30px 20px 0px 20px;
      .form-index {
        display: flex;
        min-width: 40px;
        margin-right: 5px;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
        color: #1f1f1f;
        .requireImg {
          width: 20px;
          height: 20px;
          margin-top: 3px;
        }
      }
      .form-content {
        width: calc(100% - 40px);
        .form-question {
          margin-top: 20px;
        }
      }
    }
  }
</style>
